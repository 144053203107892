import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "account" */ '../views/Home.vue')
    },
    {
        path: '/unknownuser',
        name: 'unknownuser',
        component: () => import(/* webpackChunkName: "about" */ '../views/Errors/Unknow.vue')
    },
    {
        path: '/identityservererror',
        name: 'identityservererror',
        component: () => import(/* webpackChunkName: "about" */ '../views/Errors/IdentityServerError.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/LoginCandidate.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/CreateAccountCandidate.vue')
    },
    {
        path: '/conditions-utilisation',
        name: 'conditions-utilisation',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ConditionsUtilisation.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: 'account' */ '../views/Account/ResetPasswordCandidate.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: 'account' */ '../views/Account/ChangePasswordCandidate.vue')
    },
    {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: 'account' */ '../views/Account/VerifyEmail.vue')
    },
    {
        path: '/compte',
        name: 'compte',
        component: () => import(/* webpackChunkName: 'account' */ '../views/Account/AccountCandidat.vue')
    },
    {
        path: '/calendrier',
        name: 'calendrier',
        component: () =>
            import(/* webpackChunkName: 'about' */ '../views/Inscriptions/InscriptionsCalendrier.vue')
    },
    {
        path: '/inscription/:formulaire_id',
        name: 'inscription',
        component: () => import(/* webpackChunkName: 'registration' */ '../views/Inscriptions/Inscription.vue')
    },
    {
        path: '/resultats/:phase',
        name: 'resultats',
        component: () => import(/* webpackChunkName: "about" */ '../views/Resultats/Resultats.vue')
    },
    /** PHASE ADMISSIBILITE */
    {
        path: '/reclamations/admissibilite',
        name: 'reclamation_admissibilite',
        component: () => import(/* webpackChunkName: "about" */ '../views/Reclamations/ReclamationAdmissibilite.vue')
    },
    {
        path: '/convocations/admissibilite',
        name: 'convocation_admissibilite',
        component: () => import(/* webpackChunkName: "about" */ '../views/Convocations/ConvocationAdmissibilite.vue')
    },
    /** PHASE ADMISSION */
    {
        path: '/reclamations/admission',
        name: 'reclamation_admission',
        component: () => import(/* webpackChunkName: "about" */ '../views/Reclamations/ReclamationAdmission.vue')
    },
    {
        path: '/reclamations/postconcours',
        name: 'reclamation_post_concours',
        component: () => import(/* webpackChunkName: "about" */ '../views/Reclamations/ReclamationPostConcours.vue')
    },
    {
        path: '/convocations/admission',
        name: 'convocation_admission',
        component: () => import(/* webpackChunkName: "about" */ '../views/Convocations/ConvocationAdmission.vue')
    },
    {
        path: '/demissions/concours',
        name: 'demission_concours',
        component: () => import(/* webpackChunkName: "about" */ '../views/Demissions/DemissionConcours.vue')
    },
    {
        path: '/demissions/epreuve',
        name: 'demission_epreuve',
        component: () => import(/* webpackChunkName: "about" */ '../views/Demissions/DemissionEpreuve.vue')
    },
    {
        path: '/copies',
        name: 'copies',
        component: () => import(/* webpackChunkName: "about" */ '../views/Copies/Copies.vue')
    },
    {
        path: '/assistance',
        name: 'assistance',
        component: () => import(/* webpackChunkName: "about" */ '../views/Assistance/SupportTechnique.vue')
    },
    {
        path: '/choixposte',
        name: 'choixposte',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoixPoste/ChoixPoste.vue')
    },
    {
        path: '/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "about" */ '../components/Menu.vue')
    },
    {
        path: '/version',
        name: 'Version'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.path === '/version') {
        document.documentElement.innerHTML = (document.head.querySelector('meta[name="version"]') as any).content
        return next()
    }

    // Récupère la route to pour checker si on continue d'afficher l'application ou non
    const user = store.getters['user/user_select']
    if (user && !user.email_verified) {
        if (to.name !== 'verify-email') {
            return next({ name: 'verify-email' })
        }
    }
    store.commit('auth/SET_TO_URL', to.name)
    return next()
})

export default router
